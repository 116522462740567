export {globalStyles} from "./src/styles/global_styles";
export {fontFace} from "./src/styles/font_face";
export {GhThemeProvider} from "./src/styles/GhThemeProvider";
import {themeGh as theme} from "@web2/themes";

import "@emotion/react";

export const themeGh = theme;
export type IGHTheme = typeof themeGh;

declare module "@emotion/react" {
    export interface Theme extends IGHTheme {}
}
