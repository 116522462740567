import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const CloseIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M9.56.44h0a.72.72,0,0,0-1,0L5,4,1.46.44a.71.71,0,0,0-1,0h0a.71.71,0,0,0,0,1L4,5,.44,8.54a.71.71,0,0,0,0,1h0a.71.71,0,0,0,1,0L5,6,8.54,9.56a.71.71,0,0,0,1,0h0a.71.71,0,0,0,0-1L6,5,9.56,1.46A.72.72,0,0,0,9.56.44Z" />
        </SvgIcon>
    );
};
