import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const CloseButtonIcon = (props: IProps) => {
    return (
        <svg
            className={classNames(props.className, "svg-icon modal-close-icon")}
            height={props.height || 14}
            viewBox="0 0 10 10"
            width={props.width || 14}
            fill={props.mainColor || "#757575"}
        >
            <path d="M9.78.23a.75.75,0,0,0-1.07,0L5,3.93,1.29.22A.77.77,0,0,0,.22.22a.77.77,0,0,0,0,1.07L3.93,5,.22,8.71a.77.77,0,0,0,0,1.07.77.77,0,0,0,1.07,0L5,6.07,8.71,9.78A.76.76,0,1,0,9.78,8.71L6.07,5,9.78,1.29A.75.75,0,0,0,9.78.23Z" />
        </svg>
    );
};
