import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const NotificationBellIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M8.53552 8.53552C7.59116 9.47989 6.33555 10 5 10C3.66445 10 2.40884 9.47989 1.46445 8.53552C0.520089 7.59116 0 6.33555 0 5C0 3.66445 0.520089 2.40884 1.46445 1.46445C2.40884 0.520089 3.66445 0 5 0C6.33555 0 7.59116 0.520089 8.53552 1.46445C9.47989 2.40884 10 3.66445 10 5C10 6.33555 9.47989 7.59116 8.53552 8.53552" />
            <path
                d="M4.90694 8.05625C5.21466 8.05625 5.46644 7.79296 5.46644 7.47116H4.34744C4.34744 7.79296 4.59642 8.05625 4.90694 8.05625ZM6.58544 6.30096V4.83822C6.58544 3.9401 6.12665 3.18825 5.32656 2.98932V2.79038C5.32656 2.54757 5.13913 2.35156 4.90694 2.35156C4.67475 2.35156 4.48732 2.54757 4.48732 2.79038V2.98932C3.68444 3.18825 3.22844 3.93717 3.22844 4.83822V6.30096L2.66895 6.88606V7.17861H7.14493V6.88606L6.58544 6.30096Z"
                fill="#FBFBFB"
            />
        </SvgIcon>
    );
};
