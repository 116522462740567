import * as React from "react";
import {css, jsx, keyframes} from "@emotion/react";
import {rgba} from "polished";

interface IProps {
    color?: string;
    speedMultiplier?: number;
    height?: string;
    width?: string;
    className?: string;
}

const long = keyframes`
    0% {left: -35%;right: 100%}
    60% {left: 100%;right: -90%}
    100% {left: 100%;right: -90%}
`;

const short = keyframes`
    0% {left: -200%;right: 100%}
    60% {left: 107%;right: -8%}
    100% {left: 107%;right: -8%}
`;

export const BarLoader = (props: IProps) => {
    return (
        <span css={wrapper(props)} className={props.className}>
            <span css={barLoader(1, props)} />
            <span css={barLoader(2, props)} />
        </span>
    );
};

const wrapper = (props: IProps) => {
    const {width, height, color} = props;

    return css`
        position: relative;
        display: flex;
        width: ${width || "100px"};
        height: ${height || "4px"};
        overflow: hidden;
        ${color && `background-color: ${rgba(color || "#9069c0", 0.2)}`};
        background-clip: padding-box;
    `;
};

const barLoader = (i: number, props: IProps) => {
    const {speedMultiplier} = props;

    return css`
        position: absolute;
        height: ${props.height || "4px"};
        overflow: hidden;
        background-color: ${props.color || "#9069c0"};
        background-clip: padding-box;
        display: block;
        border-radius: 2px;
        will-change: left, right;
        animation-fill-mode: forwards;
        ${speedMultiplier &&
        css`
            animation: ${i === 1 ? long : short} ${2.1 / speedMultiplier}s ${i === 2 ? `${1.15 / speedMultiplier}s` : ""}
                ${i === 1 ? "cubic-bezier(0.65, 0.815, 0.735, 0.395)" : "cubic-bezier(0.165, 0.84, 0.44, 1)"} infinite;
        `};
    `;
};
