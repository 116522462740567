import * as React from "react";
import classNames from "classnames";
import {themeGh} from "@web2/global_styles";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    strokeColor?: string;
    fillColor?: string;
    pillowColor?: string;
    size?: string;
}

export const BedIcon = (props: IProps) => {
    const {strokeColor, fillColor, size, pillowColor} = props;
    const className = classNames(props.className);

    const strokeColorOrDefault = strokeColor ? strokeColor : themeGh.colors.gray_dark;
    const fillColorOrDefault = fillColor ? fillColor : "none";
    const pillowColorDefault = pillowColor ? pillowColor : themeGh.colors.brand_primary;

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColorOrDefault}>
            <path d="M1 8.17724H9V5.79468H1V8.17724Z" stroke={strokeColorOrDefault} strokeWidth="0.375" strokeLinejoin="round" />
            <path d="M1.75781 3.38256H8.24211V1H1.75781V3.38256Z" stroke={strokeColorOrDefault} strokeWidth="0.375" strokeLinejoin="round" />
            <path d="M1 5.79479C1.00035 5.79336 1.76871 3.38257 1.76871 3.38257H8.23129L9 5.79479" stroke="#37474F" strokeWidth="0.375" />
            <path d="M1.45703 8.17749V9.00015" stroke={strokeColorOrDefault} strokeWidth="0.375" />
            <path d="M8.54297 8.17749V9.00015" stroke={strokeColorOrDefault} strokeWidth="0.375" />
            <path
                d="M4.35432 3.95498H3.11744C2.92295 3.95498 2.76562 3.79451 2.76562 3.59614V2.49503C2.76562 2.29707 2.92295 2.1366 3.11744 2.1366H4.35432C4.54881 2.1366 4.70614 2.29707 4.70614 2.49503V3.59614C4.70614 3.79451 4.54881 3.95498 4.35432 3.95498Z"
                fill={pillowColorDefault}
            />
            <path
                d="M6.94221 3.95498H5.70533C5.51084 3.95498 5.35352 3.79451 5.35352 3.59614V2.49503C5.35352 2.29707 5.51084 2.1366 5.70533 2.1366H6.94221C7.1367 2.1366 7.29403 2.29707 7.29403 2.49503V3.59614C7.29403 3.79451 7.1367 3.95498 6.94221 3.95498Z"
                fill={pillowColorDefault}
            />
        </SvgIcon>
    );
};
