import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const NotificationBellOutlineIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path
                d="M5.11537 9.99998C4.70734 9.99998 4.31603 9.83789 4.02751 9.54938C3.73899 9.26086 3.5769 8.86955 3.5769 8.46152C3.5769 8.35951 3.61743 8.26168 3.68956 8.18956C3.76168 8.11743 3.85951 8.0769 3.96152 8.0769H6.26921C6.37122 8.0769 6.46905 8.11743 6.54118 8.18956C6.61331 8.26168 6.65383 8.35951 6.65383 8.46152C6.65383 8.86955 6.49174 9.26086 6.20322 9.54938C5.9147 9.83789 5.52339 9.99998 5.11537 9.99998ZM4.44998 8.84614C4.5176 8.9627 4.61466 9.05946 4.73143 9.12672C4.84821 9.19398 4.9806 9.22939 5.11537 9.22939C5.25013 9.22939 5.38252 9.19398 5.4993 9.12672C5.61607 9.05946 5.71313 8.9627 5.78075 8.84614H4.44998Z"
                fill={props.fillColor}
            />
            <path
                d="M5.88462 1.53846H4.34616C4.24416 1.53846 4.14633 1.49794 4.0742 1.42581C4.00207 1.35368 3.96155 1.25585 3.96155 1.15385C3.96155 0.847827 4.08311 0.554342 4.2995 0.337954C4.51589 0.121566 4.80937 0 5.11539 0C5.42141 0 5.7149 0.121566 5.93129 0.337954C6.14767 0.554342 6.26924 0.847827 6.26924 1.15385C6.26924 1.25585 6.22872 1.35368 6.15659 1.42581C6.08446 1.49794 5.98663 1.53846 5.88462 1.53846Z"
                fill={props.fillColor}
            />
            <path
                d="M9.34616 8.84621H0.884616C0.78261 8.84621 0.684782 8.80569 0.612652 8.73356C0.540523 8.66143 0.500001 8.5636 0.500001 8.46159C0.499664 8.02723 0.604426 7.59923 0.805348 7.21413C1.00627 6.82903 1.29739 6.49826 1.65385 6.25006V4.23083C1.65383 3.31675 2.01535 2.43978 2.65954 1.79127C3.30372 1.14277 4.17826 0.775381 5.09231 0.769287H5.13462C6.05267 0.769287 6.93313 1.13398 7.58229 1.78315C8.23146 2.43231 8.59615 3.31277 8.59615 4.23083V6.25006C8.94907 6.50038 9.23645 6.83208 9.43395 7.21705C9.63146 7.60202 9.73327 8.02892 9.73077 8.46159C9.73077 8.5636 9.69025 8.66143 9.61812 8.73356C9.54599 8.80569 9.44816 8.84621 9.34616 8.84621ZM1.30769 8.07698H8.92308C8.8686 7.81033 8.75815 7.55829 8.59903 7.3375C8.4399 7.11672 8.23573 6.93222 8 6.79621C7.94131 6.76232 7.89261 6.71353 7.85884 6.65477C7.82507 6.59601 7.80742 6.52937 7.80769 6.46159V4.23083C7.80769 3.51678 7.52404 2.83198 7.01913 2.32708C6.51423 1.82217 5.82943 1.53852 5.11539 1.53852C4.40134 1.53852 3.71654 1.82217 3.21164 2.32708C2.70673 2.83198 2.42308 3.51678 2.42308 4.23083V6.46159C2.42335 6.52937 2.4057 6.59601 2.37193 6.65477C2.33816 6.71353 2.28946 6.76232 2.23077 6.79621C1.99504 6.93222 1.79087 7.11672 1.63174 7.3375C1.47262 7.55829 1.36217 7.81033 1.30769 8.07698Z"
                fill={props.fillColor}
            />
        </SvgIcon>
    );
};
