import * as pathToRegexp from "path-to-regexp";

import {appPath} from "./gh_app_path";

function parseParams<T extends {}>(path: string) {
    const compiler = pathToRegexp.compile(path);
    return (params: T) => compiler(params);
}

interface ISiteMapDetailParams {
    siteMapSlug: string;
}
interface IAgencyDetailParams {
    agencySlug: string;
}

interface IAgencyLocationParams {
    location: string;
}

interface IAgentDetailParams {
    agentSlug: string;
}

export interface IArticleDetailAndListParams {
    slug: string;
}

export interface IAuthorDetailParams {
    authorSlug: string;
}
interface IBuildingDetailParams {
    buildingId: string;
}
export interface IDeveloperDetailParams {
    developerSlug: string;
    developerId: string | number;
}
export interface IFullOfferDetailParams {
    offerSlug: string;
    offerId: string | number;
}
export interface IInvestmentDetailParams {
    investmentSlug: string;
    investmentId: string | number;
}
export interface IIndividualOffersParams {
    uuid: string;
    propertyType: string;
}
export interface IIndividualOffersVerifyOrRenew {
    uuid: string;
    token: string;
}

export interface IPageDetailParams {
    slug: string;
}
export interface ISearchResultUniversalParams {
    offerType?: string;
    type?: string;
    location?: string;
    subLocation?: string;
    subSubLocation?: string;
}

export interface ISearchResultUniversalTagParams extends ISearchResultUniversalParams {
    tag: string;
}

interface IVendorDetailParams {
    vendorSlug: string;
}
interface IDeveloperLocationParams {
    location: string;
}
export const appLink = {
    aboutUs: () => appPath.aboutUs,
    siteMap: {
        base: () => appPath.siteMap.base,
        detail: parseParams<ISiteMapDetailParams>(appPath.siteMap.detail) // path handles both - article detail and list
    },
    agency: {
        base: () => appPath.agency.base,
        detail: parseParams<IAgencyDetailParams>(appPath.agency.detail),
        location: parseParams<IAgencyLocationParams>(appPath.agency.location)
    },
    agencyRegister: () => appPath.agencyRegister,
    agencyRegisterPremium: () => appPath.agencyRegisterPremium,
    agent: {
        base: () => appPath.agent.base,
        detail: parseParams<IAgentDetailParams>(appPath.agent.detail)
    },
    article: {
        base: () => appPath.article.base,
        detailOrCategoryList: parseParams<IArticleDetailAndListParams>(appPath.article.detailOrCategoryList), // path handles both - article detail and list
        author: {
            base: () => appPath.article.author.base,
            detail: parseParams<IAuthorDetailParams>(appPath.article.author.detail)
        }
    },
    building: {
        base: () => appPath.building.base,
        detail: parseParams<IBuildingDetailParams>(appPath.building.detail)
    },
    buylando: () => appPath.buylando,
    developer: {
        base: () => appPath.developer.base,
        detail: {base: parseParams<IDeveloperDetailParams>(appPath.developer.detail)},
        location: parseParams<IDeveloperLocationParams>(appPath.developer.location)
    },
    favourites: () => appPath.favourites,
    forAgent: () => appPath.forAgent,
    fullOffer: {
        base: () => appPath.fullOffer.base,
        detail: {
            base: parseParams<IFullOfferDetailParams>(appPath.fullOffer.detail.base)
        }
    },
    investment: {
        base: () => appPath.investment.base,
        detail: parseParams<IInvestmentDetailParams>(appPath.investment.detail)
    },
    mainPage: () => appPath.mainPage,
    page: {
        base: () => appPath.page.base,
        detail: parseParams<IPageDetailParams>(appPath.page.detail) // path handles both - article detail and list
    },
    offer: {
        add: appPath.offer.add,
        edit: parseParams<IIndividualOffersParams>(appPath.offer.edit),
        verify: parseParams<IIndividualOffersVerifyOrRenew>(appPath.offer.verify),
        renew: parseParams<IIndividualOffersVerifyOrRenew>(appPath.offer.renew)
    },
    searchResult: {
        universal: parseParams<ISearchResultUniversalParams>(appPath.searchResult.universal),
        universalTag: parseParams<ISearchResultUniversalTagParams>(appPath.searchResult.universalTag)
    },
    terms: () => appPath.terms,
    termsRodo: () => appPath.termsRodo,
    yourOffers: () => appPath.yourOffers,
    vendor: {
        base: () => appPath.vendor.base,
        detail: parseParams<IVendorDetailParams>(appPath.vendor.detail)
    }
};
