import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const FilterIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path
                d="M4.47379 7.84368L5.41152 8.40608V5.88675C5.41152 5.79183 5.44553 5.70007 5.50763 5.62809L8.69536 1.94519C8.8496 1.76682 8.78118 1.57619 8.75627 1.52201C8.73174 1.46782 8.63287 1.29104 8.39676 1.29104H1.48856C1.25244 1.29104 1.15357 1.46782 1.12905 1.52201C1.10413 1.57619 1.03571 1.76682 1.18956 1.9448L4.37768 5.62809C4.43978 5.70007 4.47379 5.79183 4.47379 5.88675V7.84368ZM5.80702 9.5C5.73622 9.5 5.66583 9.48141 5.60294 9.44384L3.875 8.40684C3.75596 8.33525 3.68279 8.20632 3.68279 8.0675V6.03423L0.591954 2.46287C0.28386 2.10731 0.213856 1.62085 0.409629 1.19331C0.605006 0.765776 1.0187 0.5 1.48855 0.5H8.39675C8.86661 0.5 9.2803 0.765776 9.47568 1.19331C9.67145 1.62085 9.60145 2.10731 9.29375 2.46287L6.20252 6.03423V9.1045C6.20252 9.24728 6.12579 9.37858 6.0016 9.44898C5.94149 9.48339 5.87425 9.5 5.80702 9.5Z"
                fill={props.fillColor}
            />
        </SvgIcon>
    );
};
